window.addEventListener('load', () => {
	if (typeof window.cnfsd_carousel_script_loaded !== 'undefined') {
		return;
	}

	const carouselNodes = document.querySelectorAll('.mod-carousel');
	if (!carouselNodes.length) return;

	class Carousel {
		constructor(carousel) {
			this.carousel = carousel;
			this.slider = this.carousel.querySelector('.mod-carousel__slides');
			this.outer = this.carousel.querySelector('.mod-carousel__outer');
			this.slides = this.carousel.querySelectorAll('.mod-carousel__slide');
			this.navButtonsContainer = this.carousel.querySelector('.mod-carousel__buttons');
			this.navButtons = this.carousel.querySelectorAll('.mod-carousel__buttons > button');
			this.carouselLeftPosition = 0;
			this.eachSlideWidth = 0;
			this.sliderPosition = 0;
			this.maxScroll = 0;
			this.windowWidth = 0;
			this.sliderGap = 0;
		}

		init = () => {
			if (!this.slider) return false; //IF CAROUSEL HAS NO SLIDER, THE CAROUSEL CLASS IS ADDED FOR STYLISTIC PURPOSES

			this.initCarouselWidths();

			this.handleTouchEvents();
			this.handleMouseEvents();
			this.handleButtonEvents();
			this.handleTransitionEndEvent();

			this.addSlideIndexLabels();
			this.setSlideActiveState();
		};

		initCarouselWidths = () => {
			this.getWindowWidth();
			this.getSliderGap();

			this.setSlideWidth();

			this.setSliderWidth();
			this.setMaxScroll();

			this.getCarouselLeftPosition();
		};

		toggleButtonVisibility = sliderWidth => {
			if (sliderWidth <= this.outer.offsetWidth) {
				this.navButtonsContainer.style.display = 'none';
			} else {
				this.navButtonsContainer.style.display = '';
			}
		};

		getCarouselLeftPosition = () => {
			this.carouselLeftPosition = this.outer.getBoundingClientRect().left;
		};

		reCalcOnResize = () => {
			if (!this.slider) return false; //IF CAROUSEL HAS NO SLIDER, THE CAROUSEL CLASS IS ADDED FOR STYLISTIC PURPOSES
			this.initCarouselWidths();

			if (this.maxScroll < 0) {
				this.setSliderPosition(0);
			} else if (this.sliderPosition * -1 > this.maxScroll) {
				this.setSliderPosition(this.maxScroll * -1);
			}

			this.positionSliderOnScrollEnd();
			this.setSlideActiveState();
		};

		handleTransitionEndEvent = () => {
			this.slider.addEventListener('transitionend', this.setSlideActiveState);
		};

		handleButtonEvents = () => {
			if (!this.navButtons.length) return;

			this.handleDisableButtons(0);

			const [prevBttn, nextBttn] = this.navButtons;
			prevBttn.addEventListener('click', e => this.rotateSliderOnButtonPress(this.eachSlideWidth));
			nextBttn.addEventListener('click', e => this.rotateSliderOnButtonPress(this.eachSlideWidth * -1));
		};

		rotateSliderOnButtonPress = distance => {
			const newSliderPosition = distance + this.sliderPosition;
			if (this.checkCarouselBoundaries(newSliderPosition)) this.setSliderPosition(newSliderPosition);

			//MAKE ALL SLIDES VISIBLE
			this.slides.forEach(slide => {
				slide.removeAttribute('aria-hidden');
			});
		};

		handleMouseEvents = () => {
			let carouselPositionOnMouseDown = 0,
				initialX = 0,
				moving = false;

			this.slider.addEventListener('mousedown', e => {
				carouselPositionOnMouseDown = this.sliderPosition;
				this.slider.classList.add('mod-carousel__slides--grab');
				initialX = e.clientX;
				moving = true;

				const self = this;

				//MAKE ALL SLIDES VISIBLE
				self.slides.forEach(slide => {
					slide.removeAttribute('aria-hidden');
				});

				window.addEventListener('mousemove', function moveSliderWithMouse(e) {
					e.preventDefault();

					if (!moving) {
						this.window.removeEventListener('mousemove', moveSliderWithMouse);
						return false;
					}

					self.slider.classList.add('mod-carousel__slides--moving');
					self.scrollSliderOnDrag(initialX, e.clientX, carouselPositionOnMouseDown);
				});

				window.addEventListener('mouseup', function mouseSliderEnd(e) {
					moving = false;
					self.positionSliderOnScrollEnd();

					window.removeEventListener('mouseup', mouseSliderEnd);
				});
			});
		};

		handleTouchEvents = () => {
			let carouselPositionOnTouchStart = 0,
				initialX = 0,
				initialY = 0;

			this.slider.addEventListener('touchstart', e => {
				//SNAPSHOT OF CAROUSEL ON INITAL INTERACTION
				carouselPositionOnTouchStart = this.sliderPosition;
				this.slider.classList.add('mod-carousel__slides--grab');
				initialX = e.touches[0].clientX;
				initialY = e.touches[0].clientY;

				//MAKE ALL SLIDES VISIBLE
				this.slides.forEach(slide => {
					slide.removeAttribute('aria-hidden');
				});
			});

			this.slider.addEventListener('touchmove', e => {
				this.slider.classList.add('mod-carousel__slides--moving');

				const xPosition = Math.abs(e.touches[0].clientX - initialX),
					yPosition = Math.abs(e.touches[0].clientY - initialY);

				//IF USER SCROLLS MORE VIRTICALLY SCROLL WINDOW OVER CAROUSEL
				if (yPosition > xPosition) return;

				this.scrollSliderOnDrag(initialX, e.touches[0].clientX, carouselPositionOnTouchStart);
			});

			this.slider.addEventListener('touchend', e => {
				const tagName = e.target.tagName;
				const parentTagName = e.target.parentNode.tagName;

				if (!this.slider.classList.contains('mod-carousel__slides--moving')) {
					if (tagName === 'A' && e.target.href) return (document.location = e.target.href);
					if (parentTagName === 'A' && e.target.parentNode.href)
						return (document.location = e.target.parentNode.href);
				}

				this.positionSliderOnScrollEnd();
			});
		};

		scrollSliderOnDrag = (initialX, movement, carouselPosition) => {
			//CALCULATE THE NEW POSITION FOR THE CAROUSEL

			const distance = initialX - movement,
				newSliderPosition = carouselPosition + distance * -1;

			if (this.checkCarouselBoundaries(newSliderPosition, 40)) this.setSliderPosition(newSliderPosition);
		};

		setSliderPosition = newSliderPosition => {
			//SETS THE CAROUSEL POSITION

			this.slider.style.transform = `translate3d(${newSliderPosition}px, 0px, 0px)`;
			this.sliderPosition = newSliderPosition;

			this.handleDisableButtons(newSliderPosition);
		};

		setSlideActiveState = () => {
			const leftSlideIndex = Math.abs((this.sliderPosition * -1) / this.eachSlideWidth);

			this.slides.forEach((slide, index) => {
				const slideBoundings = slide.getBoundingClientRect();

				if (index >= leftSlideIndex && slideBoundings.right > 0 && slideBoundings.left < this.windowWidth) {
					//IF SLIDE IS NOT INSIDE CAROUSEL BUT IS ON SCREEN, SET INACTIVE BUT NOT HIDDEN
					slide.classList.add('active');
					slide.removeAttribute('aria-hidden');
					slide.removeAttribute('inert', true);
				} else if (index < leftSlideIndex && slideBoundings.right > 0) {
					//IF SLIDE IS INSIDE OF CAROUSEL AREA, SET TO ACTIVE
					slide.classList.remove('active');
					slide.removeAttribute('aria-hidden');
					slide.setAttribute('inert', true);
				} else {
					//IF SLIDE IS NOT ON SCREEN, SET TO INACTIVE AND ARIA-HIDDEN
					slide.setAttribute('aria-hidden', true);
					slide.setAttribute('inert', true);
					slide.classList.remove('active');
				}
			});
		};

		handleDisableButtons = newSliderPosition => {
			//DISABLES BUTTONS IF END OF CAROUSEL REACHED

			if (!this.navButtons.length) return;

			const [prevBttn, nextBttn] = this.navButtons;

			prevBttn.removeAttribute('aria-disabled');
			nextBttn.removeAttribute('aria-disabled');

			if (newSliderPosition >= 0) {
				prevBttn.setAttribute('aria-disabled', true);
			}

			if (newSliderPosition * -1 >= this.maxScroll) {
				nextBttn.setAttribute('aria-disabled', true);
			}
		};

		positionSliderOnScrollEnd = () => {
			//ROTATES THE CAROUSEL TO LEFT-ALIGNED ON SCROLL END

			const slidePositionedLeft = Math.round((this.sliderPosition * -1) / this.eachSlideWidth),
				newSliderPosition = slidePositionedLeft * this.eachSlideWidth * -1;

			this.slider.classList.remove('mod-carousel__slides--moving');
			this.slider.classList.remove('mod-carousel__slides--grab');
			this.setSliderPosition(newSliderPosition);
		};

		checkCarouselBoundaries = (newSliderPosition, scrollMargin = 0) => {
			//RETURN FALSE IF CAROUSEL BOUNDARY REACHED, ELSE RETURN TRUE

			if (newSliderPosition * -1 > this.maxScroll + scrollMargin) return false;
			if (newSliderPosition > scrollMargin) return false;
			return true;
		};

		addSlideIndexLabels = () => {
			this.slides.forEach((slide, index) => {
				slide.setAttribute('aria-label', `${index + 1} of ${this.slides.length}`);
			});
		};

		getWindowWidth = () => {
			this.windowWidth = document.documentElement.clientWidth;
		};

		getSliderGap = () => {
			this.sliderGap = parseInt(window.getComputedStyle(this.slider).getPropertyValue('gap'));
		};

		setSlideWidth = () => {
			if (!this.slides.length) return;

			this.slider.style.width = `auto`;

			this.slides.forEach(slide => {
				slide.style.width = '';

				if (slide.offsetWidth > this.outer.offsetWidth) {
					slide.style.width = `${this.outer.offsetWidth}px`;
				} else {
					slide.style.width = `${slide.offsetWidth}px`;
				}
			});

			this.eachSlideWidth = this.slides[0].offsetWidth + this.sliderGap;
		};

		setSliderWidth = () => {
			//CAROUSEL WIDTH IS SLIDE WIDTH * NUMBER OF SLIDES - 1 SLIDER GAP
			const sliderWidth = this.eachSlideWidth * this.slides.length - this.sliderGap;

			this.slider.style.width = `${sliderWidth}px`;

			this.toggleButtonVisibility(sliderWidth);
		};

		setMaxScroll = () => {
			//CALCULATE NUMBER OF SLIDES THAT CAN FIT IN THE OUTER CONTAINER

			const maxSlidesInOuter = Math.floor(this.outer.offsetWidth / this.eachSlideWidth);

			if (maxSlidesInOuter > 0) {
				this.maxScroll = (this.slides.length - maxSlidesInOuter) * this.eachSlideWidth;
			} else {
				this.maxScroll = (this.slides.length - 1) * this.eachSlideWidth;
			}
		};
	}

	const carouselInstances = [];
	carouselNodes.forEach(carouselInstance => {
		const carousel = new Carousel(carouselInstance);
		carousel.init();
		carouselInstances.push(carousel);
	});

	let timeout;
	window.addEventListener('resize', function (e) {
		clearTimeout(timeout);
		timeout = setTimeout(function () {
			carouselInstances.forEach(carousel => {
				carousel.reCalcOnResize();
			});
		}, 100);
	});

	if (typeof cnfsd_carousel_script_loaded === 'undefined') {
		window.cnfsd_carousel_script_loaded = true;
	}
});
